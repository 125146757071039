:root {
    --primary: #94987c;

}

.btn{
    padding: 8px 20px;
    border-radius: 2px;
    outline: none;
    border: none;
    cursor:pointer;
}

.btn--primary {
    background-color: var(--primary);
    color:#fff;
    border: 1px solid var(--primary);

}

.btn--outline{
     background-color:  transparent;
     color:#94987c;
     padding: 8px 20px;
     border: 1px solid var(--primary);
     transition: all 0.3s ease-out;

}

.btn--inline{
    background-color:  transparent;
    color:#ffffff;
    padding: 8px 20px;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;

}

.btn--medium {
padding: 8px 20px;
font-size: 20px;
}

.btn--large {
    padding:  12px 26px;
    font-size: 20px;
}

.btn--medium:hover, .btn--large:hover {
background-color: #fff;
color:#242424;
transition: all 0.3s ease-out;
}
 
.bt{
    background-color:   var(--primary);
     color:#ffffff;
     padding: 8px 20px;
     border: 1px solid var(--primary);
     transition: all 0.3s ease-out;
     padding:  12px 26px;
    font-size: 20px;
   
}
.bt:hover {
    background-color: #fff;
    color:#242424;
    transition: all 0.3s ease-out;
    }

    
.btage{
    background-color:   var(--primary);
     color:#ffffff;
     padding: 8px 20px;
     border: 1px solid var(--primary);
     transition: all 0.3s ease-out;
     padding:  8px 46px;
    font-size: 20px;
    margin-top:5%;
   
   
}
.btage:hover {
    background-color: #fff;
    color:#242424;
    transition: all 0.3s ease-out;
    }