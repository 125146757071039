.row-ase{
    display: flex;
margin-top: 5%;
}

.titulo-agenda>h1{
    text-align: center;
    padding: 5%;
}
.titulo-agenda{
    text-align: center;
    padding: 5%;
}
.col-ase{
    flex:1;
    padding-right: 0%;
}
.imagen-pack-row-ase{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    
}
.imagen-pack-row-1-ase:first-child{
    width: 400px;
    margin-left: auto;
    
 
}
.col-ase-r{
    flex: 1;
    margin-left: 5%;
    margin-top: 5%;
}


.col-ase-r > h1 {
    font-family: printed;
    text-align: left;
    font-size: 42px;
    margin-left: -20%;
    margin-bottom: 0%;
    font-weight: lighter;
}
.col-ase-r >p{
    margin-right:50%;
    line-height: 35px;
    margin-top: 3%;
    margin-left: -5%;
}
.col-ase-r > h2{
    margin-top: -3%;
    margin-left: -10%;
}

.contenido-centro{
margin-bottom: 1%;
}
.contenido-centro > h1{
    font-family: printed;
    text-align: center;
    font-size: 42px;
   margin-top: 5%;
    margin-bottom: 1%;
    font-weight: lighter;
}
.contenido-centro >p{
    margin-left: 20%;
    margin-right: 20%;
    text-align: center;
    color: grey;
    line-height: 35px;
    margin-bottom: 1%;
}



.row-ase2{
    display: flex;
}

.col-ase2{
    flex: 1;
    margin-top: 1%;
    padding-left: 20%;
    
}


.col-ase2 >h1{
    font-family: printed;
    text-align: right;
}

.col-ase2 > h2{
text-align: right;
font-weight: lighter;
line-height: 35px;

}
.col-ase2 >p{
text-align: right;
color: grey;


}

.col-ase-r2{
    flex: 1;
}

.row-ase3{
    display: flex;
   
    margin-top: 0%;

   

}
.col-ase3{
    flex: 1;
    margin-left: 10%;

   
}
.col-ase3 >h1{
font-family: printed;
font-weight: lighter;
text-align: left;
}
.col-ase3 >h2{
    line-height: 45px;
    margin-left: 20%;
    font-weight: lighter;
   
}
.col-ase3 >p{
    line-height: 25px;
    padding-left: 10%;
    color: grey;
    font-weight: lighter;
    
}
.col-ase3  >h3 {
    text-align: left;
    font-weight: lighter;
padding-left: 10%;
padding-top: 1%;
color: grey;
}



.col-ase-r3{
    flex: 1;
    margin-right: 10%;
}
.col-ase-r3 >h1{
    font-family: printed;
    font-weight: lighter;
    text-align: left;
   
    }
    .col-ase-r3 >h2{
        line-height: 45px;
        margin-left: 10%;
        font-weight: lighter;
       
    }
    .col-ase-r3 >p{
        line-height: 25px;
        padding-left: 10%;
        color: grey;
       
    }
    .imagen-row-ase{
        display: flex;
        justify-content: end;
        width: 700px;
        margin-left: 40%;
        margin-top: -10%;
        margin-bottom: 5%;
        
    }

    .imagen-pat-ase{
        width: 200px;
    }

    @media screen and (max-width: 768px) {

.row-ase, .row-ase2,.row-ase3{
    flex-direction: column;
}
.col-ase-r {
    margin-left :15% ;
}

.col-ase-r >p, .col-ase-r>h2 {
    margin-right: 5%;
}

.col-ase-r >h1, .col-ase2 >h1, .col-ase2 >h2{
   font-size: 22px;
    text-align: center;
}



.contenido-centro > h1 {
    font-size: 22px;
    text-align: center;
}

.contenido-centro >p {
    margin: 5%;
}
.col-ase2{
    flex: auto;
    margin-top: 0%;
    padding: 5%;

}
.col-ase2 >p {
    text-align: center;
    color: grey;
}


.col-ase-r3 >h1 {
    font-family: printed;
    font-weight: lighter;
    text-align: left;
    padding-left: 10%;
}
.col-ase3>h3{
    padding-bottom: 5%;
}

.col-ase-r3 >p{
    font-family: printed;
    font-weight: lighter;
    text-align: left;
    padding-left: 20%;
}
.imagen-row-ase {
    display: flex;
    justify-content: center;
    width: 320px;
    margin-left:0%;
    margin-top: 0%;
    margin-bottom: 10%;
}

.imagen-pack-row-1-ase:first-child {
    width: 300px;
    margin-left: 0%;
    
}

.imagen-pack-row-ase {
    display: flex;
    align-items:center;
    justify-content: center;
}
.titulo-agenda{
    font-family: printed;
    text-align: center;
    font-size: 22px;
    font-weight: lighter;
}
    }


.form-centro{
    display: flex;
    justify-content: center;
padding-bottom: 1%;
    }
.titulo-agenda>h1{
    font-family: printed;
    text-align: center;
    font-size: 42px;
    font-weight: lighter;
}
.btageal{
    display: flex;
    justify-content: center;
  
}