.bi-row{
    display: flex;
    padding-top: 5%;
}

.bi-col{

    display: flex;
    flex: 1;
    align-content: center;
    justify-content: right;
    padding-bottom: 5%;
 
     margin-left: 10%;


}
.bi-col > h1{
 font-family: printed;
 

}
.bi-col-r{

    display: flex;
    flex: 1;
    align-content: center;
    justify-content:center;
    padding-bottom: 5%;
     padding-right: 20%;
     padding-left: 5%;
 
    
}
.bi-col-r >p{
    margin-right: 20%;
   text-align: justify;
   line-height: 35px;
}




.bi-row2{
    display: flex;
}
.bi-col2{
    display: flex;
    flex: 1;
    align-content: center;
    justify-content: center;
    padding-left: 20%;
    padding-top: 5%;
    padding-bottom: 5%;
    padding-right: 10%;
    background: linear-gradient(to right, #f6e5db, #fff);
    line-height: 35px;
    text-align: justify;
}

.bi-col2-r{
    flex: 1;
 
    padding-top: 5%;
    padding-bottom: 5%;
}
.bi-col2-r > h1{
    font-family: printed;
    text-align: left;
    margin-left: -20%;
   }





.bi-row3{
    display: flex;
}
.bi-col3{
    display: flex;
    flex: 1;
    align-content: center;
    justify-content: right;
     padding-top: 10%;
     padding-bottom: 10%;
    
}
.bi-col3-content >p{
    padding-left: 35%;
    line-height: 35px;
    font-style: italic;
}
.bi-col3-r{
  
    flex: 1;
    
}
.bi-imagen-row{
    width: 450px;
}
.bi-imagen-pat{
    width: 450px;
}

.bi-centro {
    padding-left: 20%;
    padding-right: 20%;
    padding-bottom: 5%;
    
        }
    
        .bi-title > h1{
            font-family: printed;
            text-align: center;
            
            font-weight: lighter;
        }
        .bi-contenido >p{
            text-align: center;
            line-height: 30px;
            font-weight: lighter;
            color: grey;
            padding: 0 20% 0 20%;
            padding-left: 20%;
            
        }
        .bi-contenido >h2{
            font-weight: lighter;
            text-align: center;
            padding-top: 1%;
            padding-bottom: 1%;
        }



        .bi-row-f{
            display: flex;
            margin-top: 3%;
        }
        .bi-col-f{
            flex: 1;
            margin-left: 15%;
            margin-right: 10%;

        }
        .bi-col-f >h2{
            font-weight: lighter;
        }
        .bi-col-f >p{
            padding-bottom: 10%;
            line-height: 25px;
        }
        .bi-col-f-r{
            flex: 3;
       margin-right: -25%;
       margin-top: -8%;
        }
    
    .bi-imagen-pat-f{
        width: 1250px;
        border-color: black;
        border-style: solid;
        border-width: 20px;
        border-radius: 10px;
       
    }
    .bi-centro-centro{

        padding-bottom: 5%;
    }

    .bi-solicita{
        display: flex;
        align-content: center;
        justify-content: center;
    }

    @media screen and (max-width: 768px) {
        .bi-row,.bi-row2,.bi-row3,.bi-row-f{
            flex-direction: column;
        }

.bi-col3,.bi-col-f{
   justify-content: center;
   padding: 5%;
   margin: 0%;
}

.bi-col,.bi-col-r {
    justify-content: center;
    margin: 0%;
    padding-bottom: 0%;
    padding-right: 5%;
}
.bi-col>h1,.bi-title>h1{
   font-size: 22px;
}

.bi-col-r >p{
    margin: 5%;
    text-align: center;
}

.bi-col2,.bi-centro{
    justify-content: center;
padding: 0%;
   margin: 0%;
   margin-top: 0%;
  
  
  
   
}
.bi-col2-r{
    display: flex;
    margin-top:-125% ;
    margin-bottom: 115%;
    justify-content: center;
   
}
.bi-col2-r >h1{
  
   margin-left: 5%;
}
.bi-col3-content >p{
   padding: 5%;
   text-align: center;
}
 .bi-imagen-pat{
 
   
  width: 200px;
   
 }

.bi-contenido >p{
    line-height: 25px;
}
 .bi-contenido >h2, .bi-contenido >p, .bi-col-f >h2,.bi-col-f>p{
     font-size: 14px;
    padding: 5%;
    font-weight: bold;
 }

 .bi-imagen-pat-f {
    
    width: 250px;
    border-color: black;
    border-style: solid;
    border-width: 10px;
    border-radius: 10px;
}

.bi-col-f >h2{
font-size: 22px;
text-align: center;
}.bi-col-f >p{
    font-size: 12px;
    text-align: center;
}

.bi-col-f-r {
  display: flex;
  justify-content: center;
    margin-right: 0%;
    margin-top: 0%;
    padding-bottom: 5%;
    
}
.bi-colf-r{
    display: flex;
    justify-content: center;
}
.bi-imagen-row{
    display: flex;
     justify-content: center;
     width: 200px;
     
}

.bi-col3-r{
    display: flex;
     justify-content: center;
   
}

.bi-solicita{
    padding-bottom: 10%;
}
    }