.navbar {
    background: rgb(255, 255, 255);
    height: 210px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.9rem;
    position: sticky;
    top: 0;
    z-index: 999;
  }
  
  .navbar-container {
    display:absolute;
    justify-content: center;
    align-items: center;
    height: 180px;
    max-width: 1500px;
  }
  
  .navbar-logo {
    color: #fff;
    justify-self: start;
    margin-left: 150px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
   
    
  }
  .tienda-button{
    overflow: hidden;
    float: right;
    display: block;
    margin-top: 60px;
    margin-right: 10rem;   
     }
  
  .fa-typo3 {
    margin-left: 0.5rem;
    font-size: 1.8rem;
  }
  
  .nav-menu {
      
    display:grid;
    grid-template-columns: repeat(7, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 80vw;
    height: 60px;
    justify-content: center;
    margin-right: 0rem;
    
  }
  
  .nav-item {
    height: 60px;
  }
  
  .nav-links {
    color: #242424;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 80%;
  }
  
  .nav-links:hover {
    border-bottom: 4px solid #94987c;
    transition: all 0.2s ease-out;
  }
  
  .fa-bars {
    color: #cc938c;
  }
  
  .nav-links-mobile {
    display: none;
  }
  
  .menu-icon {
    display: none;
  }
  
  @media screen and (max-width: 960px) {
  
    .NavbarItems {
      position: relative;
    }
    .navbar {
      background: #fff;
      height: 150px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 0.9rem;
      position: sticky;
      top: 0;
      
      
    }
    .navbar-container {
      display:absolute;
      justify-content: center;
      align-items: center;
      height: 150px;

    }
  
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 85vh;
      position: absolute;
      top: 80px;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
    }
  
    .nav-menu.active {
      background: #fff;
      left: 0;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
      top: 9rem;
      
    }
  
    .nav-links {
      text-align: center;
      padding: 1rem;
      width:100%;
      display: table;
    }
  
    .nav-links:hover {
      background-color: #94987c;
      color: #fff;
      border-radius: 0;
    }
  
    .navbar-logo {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(25%, 50%);
    }
  
    .menu-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
    }
  
    .fa-times {
      color: #94987c;
      font-size: 2rem;
    }
  
    .nav-links-mobile {
      display: block;
      text-align: center;
      margin: 2rem auto;
      border-radius: 4px;
      width: 80%;
      text-decoration: none;
      font-size: 1.5rem;
      background-color: transparent;
      color: #94987c;
      padding: 14px 20px;
      border: 1px solid #94987c;
      transition: all 0.3s ease-out;
    }
  
    .nav-links-mobile:hover {
      background: #94987c;
      color: #fff;
      transition: 250ms;
    }
    .navbar-logo-mob {
        position: center;
        margin-left:0px;
       
    
    
        }

      
  }
  