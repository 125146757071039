* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'PT Sans', sans-serif;
  }
  @font-face {
    font-family: 'printed';
    src:  url(/public/font/printedmoments.woff2) format('woff2');
  }


 .header{
    background-color: #F6F3EC;
    padding-bottom: 8%;
    padding-top: 8%;
  }
.header >h1{
  font-size: 44px;
    text-align: center;
    color: black;
    font-family: printed;
    font-weight: lighter;
}
.header> p{
  font-size: px;
  line-height: 45px;
  padding-right: 0%;
  text-align: center;
  color: #222222;
  
 
}

 
  .pagina{
    display: flex;
   height:1181px;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
  }


  

  .pagina {
    background-image: url('https://visual-hole.imgix.net/pagina1.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
    height: 80vh;
  }

  
  
  .pagina >h1{
    font-size: 44px;
      text-align: center;
      color: black;
      font-family: printed;
      font-weight: lighter;
      padding-top: 1%;
  }
  

  .branding-home{
    display: block;
   height:1181px;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
    align-content: center;
    padding-top: 8%;
  }
  
  .branding-home {
    background-image: url('https://visual-hole.imgix.net/Branding-home.jpg');
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
    height: 80vh;
  }  

  .branding-home >h1{
    font-size: 44px;
      text-align: center;
      color: black;
      font-family: printed;
      font-weight: lighter;
      padding-top: 1%;
      align-content: center;
      
  }
  .branding-home >p{
    
     font-size:16px;
    line-height: 45px;
    padding-right: 0%;
    text-align: center;
    color: #222222;
    
   
  }

  .ti-home {
    background-image: url('https://visual-hole.imgix.net/ti-home.jpg');
    background-position: bottom;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
    height: 80vh;
  }  
  
  .ti-home >h1{
    font-size: 44px;
      text-align: center;
      color: black;
      font-family: printed;
      font-weight: lighter;
      padding-top: 1%;
      align-content: center;
      
  }
  .ti-home >p{
    
     font-size:16px;
    line-height: 45px;
    padding-right: 0%;
    text-align: center;
    color: #222222;
    
   
  }

  @media screen and (max-width: 768px){
    .branding-home {
      background-image: url('https://visual-hole.imgix.net/Branding-home.jpg');
      background-position: top;
      background-size: cover;
      background-repeat: no-repeat;
      color: #fff;
      font-size: 100px;
      height: 50vh;
    } 
  
 .branding-home >p{
   padding-left: 10%;
   padding-right: 10%;
 }
 .ti-home >p{
  padding-left: 10%;
  padding-right: 10%;
}
.ti-home {
 background-image: url('https://visual-hole.imgix.net/ti-home.jpg');
 background-position: top;
 background-size: cover;
 background-repeat: no-repeat;
 color: #fff;
 font-size: 100px;
 height: 50vh;
} 
  .header >p{
    line-height: 25px;
    padding-left: 10%;
    padding-right: 10%;
  }

  .pagina {
    background-image: url('https://visual-hole.imgix.net/pagina1.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
    height: 40vh;
  }

  }

  
label {
  font-weight: lighter;
  display: flex;
  margin-bottom: 5px;
}

input + label {
  font-weight: lighter;
  display: inline-flex;
  margin-right: 20px;
  
}

input[type='text'],
input[type='email'],
input[type='password'],
textarea,
select {
  width: 300px;
  background: 
      linear-gradient(#cc938c, #cc938c) center bottom 5px /calc(100% - 10px) 2px no-repeat;
  background-color: #fcfcfc;
  border: 0px solid;
  padding: 10px;
}

.form-control {
  margin-bottom: 20px;
}

.error {
  color: red;
}