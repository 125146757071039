.col-r>p {
padding-left: 3%;
padding-top: 15%;
padding-right: 30%;
}

.col>h1 {
  padding-left: 15%;
  padding-top: 15%;
  text-align: right;
  }
  .col-r>p {
    padding-left: 15%;
    padding-top: 20%;
    text-align: left;
    }
.imagen-pack-row-1{
  width: 400px;
  margin-left: 25%;
}

.row-pack-01{
  display: flex;
  padding-top: 5%;
  padding-left: 20%;
}

.col-pack-01-r{
  padding-left: 15%;
 
}

.col-title > h1 {
  padding-top: 0%;
  font-size: 42px;
  text-align: left;
  color: #222222;
  font-family: printed;
}

.row-pack-02{
  display: flex;
}
.col-pack-02{
  flex: 1;
  padding-left: 30%;
  padding-top: 0%;
  padding-bottom: 1%;
  background-color: #e9e9e0;
  margin-top: 5%;
  margin-bottom: 5%;
  padding-right: 10%;
}
.col-pack-02-r{
  flex:1;
  margin-top: 3%;

}
.imagen-pack-row-2{
  width: 480px;
  margin-left: -20%;
}
.imagen-pack-row-4  {
  width: 520px;
  margin-left: -20%;
}

.col-content{
  line-height: 30px;
}

.container-02{
  padding-top: 5%;
  padding-bottom: 5%;
}
.container-02 > h1{
  padding:5% 10% 3% 10%; 
  font-size: 22px;
  color: grey;
  text-align: center;
  line-height: 45px;
}
.container-02 > h2{
  padding:0% 10% 2% 10%; 
  text-align: center;
  font-family: printed;
  font-size: 42px;
  line-height: 55px;

}
.container-02 >p{
  padding:0% 30% 1% 30%; 
  text-align: center;
  line-height: 35px;
  color: grey;
}
.portafolio-contenido{
  padding-bottom: 5%;
}
.col-buttonco{
  
    display: flex;
    align-content: center;
    justify-content: left;

}
@media screen and (max-width: 768px) {

.header >h1{
  font-size: 22px;
}
.header >p{
  font-size: 12px;
}
.col-title>h1 {
  font-size: 22px;
}

.row-pack-01{
  flex-direction: column;
  padding-left: 0%;
}

.imagen-pack-row-1, .imagen-pack-row-4{
  width: 200px;
  margin-left: 0%;
  
}
.imagen-pack-row-c{
  display: flex;
  justify-content: center;
}

.row-pack-02{
  flex-direction: column;
}
.col-pack-02{
  padding-left: 15%;
}
.imagen-pack-row-2{
  width: 0px;
  margin-left:15%;
}

.container-02 >h2{
  font-size: 22px;
  font-weight: lighter;
}
.container-02 >p{
  font-size: 14px;
  padding: 5%;
}


.container > p{
  font-size: 14px;
}
}


