.container-towcol{
background-color: #fff;


}
.container-towcol > h1{
padding-top: 10%;


}

.col-sm{

    flex: 50%;
   
}
.col-sm-r{

    flex: 50%;
   
}

.col-sm-r > h1{
    padding-top: 15%;
    padding-bottom: 5%;
    text-align: left;
    padding-left: 1%;
    padding-right: 40%;
    line-height: 55px;
}
.col-sm-r >h1 >  h5{
  display: flex;
  margin-top: -13%;
  margin-left: 45%;
  text-align: left;
  
  font-family: printed;
}
.col-sm-r > p{
    padding-top: 23%;
    padding-bottom: 2%;
    text-align: justify;
    padding-left: 1%;
    padding-right: 40%;
    font-weight: lighter;
    font-size: 14px;
    line-height: 25px;
    color: grey;
}
.row-towcol{
    display: flex;
}
.row-button{
    display: flex;
    flex-flow: column;
    align-items: center;
    max-width: 100%;
    width: 91%;
    padding-bottom: 40px;
    padding-top: 0px;
    
  
}
 

.imagen-col{
    width: 100%;
    padding-top: 10%;
    padding-left: 40%;
    padding-right: 5%;
    
}

.col-full{
    display: absolute;
    padding-top: 10%;
    
    
}

.col-full >h2 {
  text-align: center;  
font-family: printed;
padding-top: 1%;
color: grey;
   
}

.col-full > p{
    padding-top: 1%;
    padding-bottom: 1%;
    text-align: center;
    padding-left: 35%;
    padding-right: 35%;
    line-height: 25px;
    color: grey;
}
.buton-home {
    display: flex;
    flex: 1;
    margin: 0 1rem;
    border-radius: 10px;
  }

@font-face {
    font-family: 'phily';
    src:  url(/public/font/PhiladelphiaStory.woff2) format('woff2');
     
  }

  
@media screen and (max-width: 960px) {
    .col-sm-r > p {
        padding-top: 1%;
        text-align: justify;
        padding-right: 5%;
        padding-left: 5%;
    }
    .col-sm-r >h1 {
        padding-right: 5%;
        padding-top: 10%;
        text-align: center;
        padding-left: 5%;
      }
      .row-towcol{
        display: block;
    }
    .imagen-col{
        width: 100%;
        padding-top: 10%;
        padding-left: 5%;
        padding-right: 5%;
        
    }
    .sobremi-button{
        padding-left: 5%;
        padding-right: 12%;
      }
      .col-full > p{
        padding-top: 1%;
        padding-bottom: 5%;
        text-align: center;
        padding-left: 5%;
        padding-right: 5%;
      
  }
  .row-button{
    display: flex;
    flex-flow: column;
    align-items: center;
    max-width: 100%;
    width: 100%;
  
}
}
  .sobremi-button{
    padding-left: 1%;
  }
 
  
  @media screen and (max-width: 768px){
    .sobremi-button{
        padding-left: 30%;
        padding-right: 30%;
      }

      .col-full > h2{
        padding-top: 1%;
        padding-bottom: 5%;
        text-align: center;
        padding-left: 5%;
        padding-right: 5%;
    font-size: 12px;
  }

  .col-full > h1{
    padding-top: 1%;
    padding-bottom: 5%;
    text-align: center;
    padding-left: 5%;
    padding-right: 5%;
font-size: 16px;
}
.col-full > p{
  padding-top: 1%;
  padding-bottom: 5%;
  text-align: center;
  padding-left: 5%;
  padding-right: 5%;
font-size: 14px;
}
.col-sm-r >h1 {
    padding-right: 5%;
    padding-top: 5%;
    text-align: center;
    padding-left: 5%;
    font-size: 16px;
    line-height: 25px;
  }

  .col-sm-r >h1 >  h5{
    display: flex;
    margin-top: 0%;
    margin-left: 0%;
    text-align: center;
    font-size: 22px;
    font-family: printed;
  }

  }
