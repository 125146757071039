/* IDENTIDAD VISUAL */
.row-pr{
    display: flex;
    margin-top: 5%;
}
.row-pr-mc{
    display:flex;
}
.col-pr{
    flex: 1 ;
    margin-left: 15%;
    padding-top: 5%;
}
.col-pr > h2{
    margin-bottom: 5%;
}
.col-pr > h3{
    margin-top: 15%;
}

.col-pr > p{
    margin-left: 0%;
    margin-right: 5%;
    line-height: 25px;
    margin-bottom: 5%;
    text-align: justify;
    
}
.slider{
    width: 80%;
}
.col-pr-r{
    flex: 3;
}

.imagen-pack-row-pr{
    
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}
.imagen-pack-row-1-pr{
    width: 80%;
}

/* MANUAL DE MARCA */
.wraper{
    display: flex;
   margin-top: 5%;
   margin-bottom: 5%;
  
}

 .box1{
    flex: 1;
    margin-left: 20%;
    margin-top: 5%;
 }
 .box2{
    flex: 3;
    width: 50%;
    height: 50%;
    align-items: center;
    justify-content: center;
    margin-right: 5%;
    
 }
  
  .box1 >h1 {
    margin-bottom: 5%;
    
    
  }
  .box1 >h2 {
    margin-bottom: 5%;
    
    
  }
  .box1  > p{
    margin-left: 0%;
    margin-right: 5%;
    line-height: 25px;
    margin-bottom: 5%;
    text-align: justify;
  }
  .box1  > h3{
    margin-top: 15%;
}
.box span {
    margin: 0 5px;
  }


  
  @media screen and (max-width: 768px){
      .row-pr{
          flex-direction: column;
      }

      .imagen-pack-row-pr {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .col-pr{
        margin: 10%;
    }

    .wraper{
        flex-direction: column;
    }
    .box1{
        margin:10%;
    }

    .box2{
       
        width: 90%;
        height: 100%;
        margin-left: 15%;
    }
  }