.sobre{
    margin-bottom: 0%;
}
.sobre-row{
display: flex;
padding-top: 5%;
}

.sobre-col{
    flex: 1;
    padding-left: 25%;
    padding-right: 5%;
    padding-top: 1%;
    }
.sobre-col-content >p{
    text-align: justify;
    line-height: 25px;
    font-weight: lighter;
    
}
.sobre-col-content-r >p{
    text-align: justify;
    line-height: 25px;
    font-weight: lighter;
    padding-right: 50%;
    padding-top: 5%;
}
    .sobre-col-r{
        flex: 2;
        
        padding-right: 15%;
        padding-top: 1%;
       
    }
    .sobre-imagen-p{
        width: 450px;
    }
    .sobre-imagen{
        width: 350px;
        height: 350px; 
border-radius: 50%;
   object-fit: cover;
object-position: 0px -15px;
margin-left: 10%;
        
        
    }
    .sobre-imagen-r{
        width: 250px;
        height: 250px; 
border-radius: 50%;
   object-fit: cover;
object-position: 0px -15px;
   
    }
    
    .sobre-centro {
padding-left: 20%;
padding-right: 20%;
    }

    .sobre-centro > h2{
        font-family: printed;
        text-align: center;
        padding-top: 5%;
        font-weight: lighter;
    }
    .sobre-centro >p{
        text-align: justify;
        line-height: 30px;
        font-weight: lighter;
        color: grey;
    }
.sobre-row-ima{
    margin-bottom: 10%;
}

    @media screen and (max-width: 768px) {
.sobre-row{
    flex-direction: column;
    padding-top: 0%;
}
.sobre-col{
    padding: 5%;
}
.sobre-col-r{
    padding-right: 0%;
}

.sobre-centro{
    padding: 5%;
}

.sobre-centro >h2{
    font-size: 22px;
}
.sobre-imagen-p{
    width: 300px;
}
.sobre-imagen-r{
    display: flex;
    justify-content: center;
    width: 250px;
    height: 250px; 
border-radius: 50%;
object-fit: cover;
object-position: 0px -15px;
padding: 0%;
margin: 0%;
}
.sobre-row-ima{
    display: flex;
    justify-content: center;
}


.sobre-imagen{
    display: flex;
    justify-content: center;
    width: 250px;
    height:250px; 
border-radius: 50%;
object-fit: cover;
object-position: 0px -15px;
margin-left: 0%;
margin-top: 130%;


}

.sobre-col-content >p {
    text-align: justify;
    line-height: 25px;
    font-weight: lighter;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 10%;
    padding-top: 0%;

}
.sobre-row-ima {
    display: flex;
    justify-content: center;
    margin-bottom: 10%;
}

    }