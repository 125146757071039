video{
    object-fit:cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
    top: 130px;
}

.hero-container{
   background:  url("https://visual-hole.imgix.net/home2024.jpg") center center/cover
    no-repeat; 
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: inset 0 0 0 1000px (0,0,0,0.2);
    object-fit: contain;
    padding-top: 205px;

}
.hero-container >h1 {
    color: #fff;
    font-size:  100px;
    margin-top: -100px;
    font-family: phily;
    font-style: bold;
}
@font-face {
    font-family: 'phily';
    src:  url(/public/font/PhiladelphiaStory.woff2) format('woff2');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }


.hero-container > p {
    margin-top: 12%;
    margin-left: 20%;
    margin-right: 20%;
    color: grey;
    font-size: 20px;
    padding: 100px;
    justify-self: center;
    text-align: center;
}

.hero-btns{
    margin-top: 32px;
}

.herio-btns .btn{
    margin: 6px ;
}

.fa-play-circle{
    margin-left:4px;

}

@media screen and (max-width: 960px){
    .hero-container > h1{
        font-size: 70px;
        margin-top: -150px;
    }
    .hero-container >p{
        font-size: 16px;
        margin-top: 8%;
        margin-left: 0%;
        margin-right: 0%;
        
    }
}

    @media screen and (max-width: 768px){
        .hero-container > h1{
            font-size: 50px;
            margin-top: -100px;
        }
        
    .hero-container >p{
    font-size: 12px;
    margin-top: 25%;
    margin-left: -20%;
    margin-right: -20%;
    margin-bottom: 0%;
    margin: 10%;
    padding: 0%;
   
    
}
.hero-container{
    background:  url("https://visual-hole.imgix.net/home2024.jpg") center center/cover
     no-repeat; 
     height: 50vh;
     width: 100%;
     display: flex;
     flex-direction: column;
     align-items: center;
     justify-content: center;
     box-shadow: inset 0 0 0 1000px (0,0,0,0.2);
     object-fit: contain;
     padding-top: 205px;
 
 }

    .btn-mobile{
    display: block;
    text-decoration: none;
}
.btn{
    width: 100%;
   

}

.hero-btns {
    margin: 0%;
    padding: 0%;

}
.col-sm-r >h1 > h5 {
 flex-direction: column;
    margin-top: 0%;
    margin-left:0%;
    text-align: center;
    font-family: printed;
}
}

