.pagina-centro {
    padding-left: 20%;
    padding-right: 20%;
    
        }
    
        .pagina-centro > h2{
            font-family: printed;
            text-align: center;
            padding-top: 5%;
            font-weight: lighter;
        }
        .pagina-centro >p{
            text-align: justify;
            line-height: 30px;
            font-weight: lighter;
            color: grey;
        }

        .pagina-titulo > h2 {
            font-family: printed;
            text-align: center;
            padding-top: 2%;
            font-weight: lighter;
        }

        .pagina-row{
            display: flex;
            padding-bottom: 1%;
            padding-left: 5%;
    padding-right: 5%;
    padding-top: 5%;
        }
.pagina-col2{
    float: left;
    width: 33.33%;
    padding-left: 3%;
    padding-right: 3%;
}
.pagina-col2 > p{
    text-align: justify;
   
    line-height: 30px;
    font-weight: lighter;
    color: grey;
}
.pagina-col2 > h2{
    font-family: printed;
    text-align: center;
    
    font-weight: lighter;
}
.pagina-col-r{
    flex: 1;
    padding-right: 20%;
   
}
.pagina-imagen-p{
    width: 450px;
}

.pagina-col-content > p{
line-height: 45px;
font-style: italic;
font-weight: lighter;

}
.pagina-col{
    display: flex;
       padding-left: 20%;
       padding-right: 5%;
       padding-top: 1%;
       align-items: center;
       justify-content: center;
       
   }
.pagina-centro-2 {
    padding-left: 20%;
    padding-right: 20%;
    
    padding-bottom: 5%;
    
        }
    
        .pagina-centro-2 > h2{
            font-family: printed;
            text-align: center;
            
            font-weight: lighter;
        }
        .pagina-centro-2 >p{
            text-align: center;
            line-height: 30px;
            font-weight: lighter;
            color: grey;
        }

        .pagina-imagen-c{
          width: 450px;
           
        }
        .pagina-col-r-2{
            display: auto;
               padding-left: 5%;
               padding-right: 5%;
               padding-top: 5%;
               align-items: center;
               justify-content: center;
               
               
           }
           .pagina-col-content-2 > p{
            line-height: 35px;
            font-style: italic;
            font-weight: lighter;
            text-align: justify;
            }

            .col-button{
                display: flex;
                align-content: center;
                justify-content: center;
              }
            @media screen and (max-width: 768px) {
.pagina >h1{
    font-size: 22px;
}

.pagina-centro, .pagina-col, .pagina-centro-2, .pagina-col-r-2{
    padding: 5%;
}
.pagina-row{
    flex-direction: column;
 
}
.col-button{
  display: flex;
  align-content: center;
  justify-content: center;
}

.pagina-col-content > p {
    line-height: 25px;
    font-style: italic;
    font-weight: lighter;
    text-align: center;
}
.pagina-col2 {
    float: left;
   width: 100%; 
     padding-left: 3%; 
     padding-right: 3%;
    
}

            }