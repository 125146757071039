.definicion{
    padding-top: 10%;
  }
  .row{
  display: flex;
  }

  .col{
    flex: 1;
   
  }

  .col > h1{
    font-size: 64px;
    padding-left: 15%;
    color: black;
    font-family: printed;
    
 
  }
  .col-r{
    flex: 1;
  }



  .container{
    padding-top: 5%;
  }
  .container > h1{
    padding:5% 10% 3% 10%; 
    font-size: 22px;
    color: grey;
    text-align: center;
    line-height: 45px;
  }
  .container > h2{
    padding:0% 10% 2% 10%; 
    text-align: center;
    font-family: printed;
    font-size: 42px;
    line-height: 55px;
    font-weight: lighter;

  }
  .container >p{
    padding:0% 30% 1% 30%; 
    text-align: center;
    line-height: 35px;
    color: grey;
  }

  .container-01{
    background-color: #e7dfd2;
   
  color: grey;
  text-align: center;
  padding-bottom: 0%;
 
  
  }
  .container-01 > h1{
    padding:2% 20% 2% 20%; 
    text-transform: uppercase;
    
    }
  .container-01 > h2{
    padding:0% 20% 1% 20%; 
    font-family: printed;
    font-size: 36px;
    font-weight: lighter;
  }

  .row-01{
    display: flex;
    }

.col-01{
  flex: 1;

}

.col-01{
  padding-top: 10%;
  padding-bottom: 1%;
  padding-left: 30%;
}
.col-title > h1{
  padding-top: 22%;
  font-size: 42px;
  text-align: left;
  color: #222222;
  font-family: printed;
}
.col-content{
  padding-top: 2%;
  padding-bottom: 2%;
  padding-left: 2%;
}

.col-time{
  text-align: left;
  
}
.col-button{
  text-align: left;
  padding-top: 2%;
padding-bottom: 2%;
}

.imagen-row-2{
  width: auto;
  height: auto;
  background-image: url('/public/images/patrones/pat6.svg');
  background-position: right;
  background-repeat: no-repeat;
  color: #fff;
  padding:40%;
  padding-top: 10%;
 margin: 0%;
 margin-top: 20%;
 

}
.row-01-r{
  display: flex;
  }

.col-01-r{
flex: 1;

}
.col-01-r{
padding-top: 1%;
padding-bottom: 5%;
margin-right: -10%;
padding-left: 30%;

}
.col-01-rr{
  padding-top: 10%;
  padding-bottom: 10%;
  flex: 1;
 
  }
.col-title-r > h1{

font-size: 42px;
text-align: left;
color: #222222 ;
font-family: printed;


}

.col-content-r{
padding-top: 2%;
padding-bottom: 2%;
line-height: 30px;

}

.col-time-r{
text-align: left;


}
.col-button-r{
text-align: left;
padding-top: 2%;
padding-bottom: 2%;

}

.imagen-row-2-r{
width: auto;
height: auto;
background-image: url('/public/images/patrones/pat1.svg');
background-position: left;
background-repeat: no-repeat;
color: #fff;
padding: 30%;

padding-left: 0%;

margin-left: -15%;





}

.imagen-pat-br{
  width: 300px;
  margin-top: -5%;
}

.col-buttonbr {
  display: flex;
  align-content: center;
  justify-content: left;
}
@media screen and (max-width: 768px) {

  .col-buttonbr {
    display: flex;
    align-content: center;
    justify-content: left;
  }
  .branding-home >h1 {
    font-size: 22px;
    padding-top: 0%;
}
.branding-home >p {
  font-size: 12px;
  line-height: 25px;
}

.container >p{
  padding: 5%;
}


.container >h2, .container-01 >h2{
  font-size: 15px;
}

.container-01 >h2 {
  padding: 5%;
}

.container-01 >h1{
  padding-top: 5%;
  
}

.row-01, .row-01-r{
  flex-direction: column;
}

.col-title-r>h1{
font-size: 22px;
}
.col-title>h1 {
  font-size: 22px;
}

.imagen-row-2 {
 background-position: center;
}

.col-01-r{
  margin-left: 0%;
  padding-left: 8%;
  margin-right: 0;
}
.imagen-row-2-r {
  background-position: center;
  margin-left: auto;
 }

 .col-button-r {
  text-align: center;
  padding-top: 5%;
  padding-bottom: 2%;
}
.col-buttonbr{
  padding-top: 5%;
  padding-bottom: 5%;
}
.col-sm-r {
  padding: 5%;
}
}