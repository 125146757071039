.cards {
    padding: 4rem;
    background: #fff;
  }
  
  h1 {
    text-align: center;
    
  }
  
  .cards__container {
    display: flex;
    flex-flow: column;
   align-items: initial;
    max-width:100%;
    width: 60%;
    margin: 0 auto;
  }
  
  .cards__wrapper {
    position: relative;
    margin: 50px 0 0px;
    
  }
  
  .cards__items {
    margin-bottom: 24px;
  }
  
  .cards__item {
    display: flex;
    flex: 1;
    margin: 0 0rem;
    border-radius: 10px;
  }
  
  .cards__item__link {
    display: flex;
    flex-flow: column;
    width: 100%;
    text-align: center;
    
    text-decoration: none;
  }
  
  .cards__item__pic-wrap {
    position: relative;
    width: 100%;
    padding-top: 90%;
    
    
  }
  
  .fade-img {
    animation-name: fade-img;
    animation-duration: 2s;
  }
  
 
  
  .cards__item__img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit:contain;
    transition: all 0.2s linear;
  }
  
  .cards__item__img:hover {
    transform: scale(1.1);
  }
  
  .cards__item__info {
    padding: 20px 30px 30px;
  }
  
  .cards__item__text {
    color: #000;
    font-size: 14px;
    line-height: 24px;
    font-weight: lighter;
  }

  .imagen-pat{
    width: 25%;


  }

  .row-button-home{
    display: flex;
    flex-flow: column;
    align-items: center;
    width: 100%;
    max-width: 100%;
    width: 90%;
    margin: 0 auto;
  
  }

  .imagen-row{
    display: flex;
    flex-flow: column;
    align-items: center;
    width: 100%;
    max-width: 100%;
    width: 90%;
    margin: 0 auto;
  }
  
  @media only screen and (min-width: 1200px) {
    .content__blog__container {
      width: 84%;
    }
  }
  
  @media only screen and (min-width: 1024px) {
    .cards__items {
      display: flex;
    }
  }
  
  @media only screen and (max-width: 1024px) {
    .cards__item {
      margin-bottom: 2rem;
    }
  }
  @media only screen and (max-width: 768px) {
    h1{
    font-size: 16px;
    }
     .cards{
       padding-left: 0;
       padding-right: 0;
     }
     .imagen-pat{
       margin-top: 20%;
      width: 25%;
  
  
    }
    
      }
  